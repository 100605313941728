* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: none;
  font-family: "Cormorant Garamond", serif;
}

body {
  background-color: rgb(32, 32, 32);
  /* background-color: orange; */
}

.container {
  width: 100vw;
  height: 100vh;
  background-color: rgb(32, 32, 32);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 5px solid orange; */
}

.img-container {
  overflow: hidden;
}

.img {
  width: 200px;
  object-fit: cover;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: slideIn forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.img.one {
  animation-delay: 150ms, 2000ms;
}

.one.positioning {
  position: fixed;
  top: 5%;
  left: 5%;
  transition: 1000ms ease;
}

.img.two {
  animation-delay: 300ms;
}

.two.positioning {
  position: fixed;
  top: 2.5%;
  left: 80%;
  transition: 1000ms ease;
}

.img.three {
  animation-delay: 450ms;
}

.three.positioning {
  position: fixed;
  top: 40%;
  left: 25%;
  transition: 1000ms ease;
}

.img.four {
  animation-delay: 600ms;
}

.four.positioning {
  position: fixed;
  top: 85%;
  left: 70%;
  transition: 1000ms ease;
}

.img.five {
  animation-delay: 750ms;
}

.five.positioning {
  position: fixed;
  top: 73%;
  left: 35%;
  transition: 1000ms ease;
}

.nav-container {
  z-index: 1;
  overflow: hidden;
}

.home {
  text-align: center;
  text-decoration: none;
  font-size: 4.5rem;
  color: whitesmoke;
}

.nav-container h2 {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.catalog {
  font-size: 1.35rem;
  text-decoration: none;
  color: whitesmoke;
  position: relative;
}

.catalog::before {
  content: "";
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  height: 40%;
  background-color: rgba(255, 255, 0, 0.5);
  transform: scaleX(0);
  transform-origin: left;
  transition: 300ms ease;
}

.catalog:hover::before {
  transform: scaleX(1);
}

.x {
  margin: 0 8px;
}

.contact {
  font-size: 1.35rem;
  text-decoration: none;
  color: rgb(150, 150, 150);
  font-style: italic;
  position: relative;
}

.contact::before {
  content: "";
  position: absolute;
  top: 85%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 0, 0.5);
  transform: scaleX(0);
  transform-origin: left;
  transition: 300ms ease;
}

.contact:hover::before {
  transform: scaleX(1);
}

/* --------- CATALOG --------- */
.catalog-container {
  height: 100vh;
  width: 100vw;
  background-color: rgb(32, 32, 32);
  display: grid;
  grid-template-areas: "topNav" "content" "bottomNav";
  grid-template-rows: 5% 90% 5%;
  transition: 1500ms ease;
  opacity: 0;
}

.catalog-content {
  grid-area: content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.catalog-content-inner {
  height: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.catalog-content-inner:hover > * {
  opacity: 0.1;
}

.catalog-content-inner:hover > *:hover {
  opacity: 1;
}

.content-inner {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  transition: 300ms ease;
}

.content-inner h1 {
  color: whitesmoke;
  font-size: 1.15rem;
}

.content-inner p {
  text-align: center;
  margin-top: 10px;
  color: rgb(150, 150, 150);
  font-size: 1rem;
  transition: opacity 300ms ease;
}

.img1 {
  display: block;
  width: 200px;
  object-fit: cover;
  position: fixed;
  top: 50%;
  left: -40%;
  opacity: 1;
  z-index: 5;
  box-shadow: 5px 5px 25px black;
  transition: left 300ms ease;
}

.img1.displayed {
  left: 10%;
}

.splattered-img {
  position: fixed;
  top: -20%;
  left: -20%;
  width: 200px;
  object-fit: cover;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 1;
  z-index: 5;
  box-shadow: 5px 5px 25px black;
  transition: 300ms linear;
}

.imgTsr-1 {
  display: block;
  width: 200px;
  object-fit: cover;
  position: fixed;
  top: 60%;
  left: 120%;
  opacity: 1;
  z-index: 5;
  box-shadow: 5px 5px 25px black;
  transition: left 300ms ease;
}

.imgTsr-1.displayed {
  left: 70%;
}

.imgTsr-2 {
  display: block;
  width: 200px;
  object-fit: cover;
  position: fixed;
  top: -40%;
  left: 5%;
  opacity: 1;
  z-index: 5;
  box-shadow: 5px 5px 25px black;
  transition: top 300ms ease;
}

.imgTsr-2.displayed {
  top: 10%;
}

.thoughts-nyc-door {
  position: fixed;
  top: 115%;
  right: -35%;
  width: 200px;
  object-fit: cover;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 1;
  z-index: 5;
  box-shadow: 5px 5px 25px black;
  transition: 300ms linear;
}

.jacket-img {
  display: block;
  width: 200px;
  object-fit: cover;
  position: fixed;
  top: 10%;
  left: 120%;
  opacity: 1;
  transform: rotate(360deg);
  z-index: 5;
  box-shadow: 5px 5px 25px black;
  transition: left 300ms ease, transform 300ms ease;
}

.jacket-img.displayed {
  left: 70%;
  transform: rotate(0deg);
}

/* CATALOG TOP */
.catalog-nav-top {
  grid-area: topNav;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.home-catalog {
  text-decoration: none;
  color: whitesmoke;
  font-size: 1rem;
  margin-left: 5px;
}

/* CATALOG BOTTOM */
.catalog-nav-bottom {
  grid-area: bottomNav;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.contact-catalog {
  text-decoration: none;
  color: rgb(150, 150, 150);
  font-style: italic;
  font-size: 1rem;
  margin-right: 5px;
  position: relative;
}

.contact-catalog::before {
  content: "";
  position: absolute;
  top: 85%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 0, 0.5);
  transform: scaleX(0);
  transform-origin: left;
  transition: 300ms ease;
}

.contact-catalog:hover::before {
  transform: scaleX(1);
}

/* ------ ERROR PAGE ------ */
.error-wrap {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-inner {
  text-align: center;
  overflow: hidden;
}

.error-inner h1 {
  color: whitesmoke;
  text-decoration: none;
  font-size: 2.25rem;
  margin-bottom: 5px;
}

.error-home {
  font-size: 1.35rem;
  text-decoration: none;
  color: whitesmoke;
  position: relative;
}

.error-home::before {
  content: "";
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  height: 40%;
  background-color: rgba(255, 255, 0, 0.5);
  transform: scaleX(0);
  transform-origin: left;
  transition: 300ms ease;
}

.error-home:hover::before {
  transform: scaleX(1);
}

.error-catalog {
  font-size: 1.35rem;
  text-decoration: none;
  color: rgb(150, 150, 150);
  font-style: italic;
  position: relative;
}

.error-catalog::before {
  content: "";
  position: absolute;
  top: 85%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 0, 0.5);
  transform: scaleX(0);
  transform-origin: left;
  transition: 300ms ease;
}

.error-catalog:hover::before {
  transform: scaleX(1);
}

/* MEDIA QUERIES */
@media (hover:hover) {
  .cursor {
    position: fixed;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    background-color: yellow;
    border-radius: 5px;
    pointer-events: none;
    z-index: 5;
  }
}

/* LAPTOP DEVICES */
@media (max-width: 1024px) {
  .img-container > img {
    width: 175px;
  }
  .content-inner > img {
    width: 175px;
  }
  .home {
    font-size: 4.25rem;
  }
  .error-inner > h1 {
    font-size: 2.125rem;
  }
  .catalog {
    font-size: 1.275rem;
  }
  .error-catalog {
    font-size: 1.275rem;
  }
  .contact {
    font-size: 1.275rem;
  }
  .error-home {
    font-size: 1.275rem;
  }
  .content-inner h1 {
    font-size: 1.1rem;
  }
  .content-inner p {
    font-size: 0.95rem;
  }
  .home-catalog {
    font-size: 0.95rem;
  }
  .contact-catalog {
    font-size: 0.95rem;
  }
}

/* TABLET DEVICES */
@media (max-width: 768px) {
  .img-container > img {
    width: 150px;
  }
  .content-inner > img {
    width: 150px;
  }
  .home {
    font-size: 4rem;
  }
  .error-inner > h1 {
    font-size: 2rem;
  }
  .catalog {
    font-size: 1.2rem;
  }
  .error-catalog {
    font-size: 1.2rem;
  }
  .contact {
    font-size: 1.2rem;
  }
  .error-home {
    font-size: 1.2rem;
  }
  .content-inner h1 {
    font-size: 1.05rem;
  }
  .content-inner p {
    font-size: 0.9rem;
  }
  .home-catalog {
    font-size: 0.9rem;
  }
  .contact-catalog {
    font-size: 0.9rem;
  }
}

/* MOBILE DEVICES */
@media (max-width: 480px) {
  .img-container > img {
    width: 125px;
  }
  .content-inner > img {
    width: 125px;
  }
  .home {
    font-size: 3.75rem;
  }
  .error-inner > h1 {
    font-size: 1.875rem;
  }
  .catalog {
    font-size: 1.125rem;
  }
  .error-catalog {
    font-size: 1.125rem;
  }
  .contact {
    font-size: 1.125rem;
  }
  .error-home {
    font-size: 1.125rem;
  }
  .content-inner h1 {
    font-size: 1rem;
  }
  .content-inner p {
    font-size: 0.85rem;
  }
  .home-catalog {
    font-size: 0.85rem;
  }
  .contact-catalog {
    font-size: 0.85rem;
  }
}

/* SMALL MOBILE DEVICES */
@media (max-width: 360px) {
  .img-container > img {
    width: 100px;
  }
  .content-inner > img {
    width: 100px;
  }
  .home {
    font-size: 3.5rem;
  }
  .error-inner > h1 {
    font-size: 1.75rem;
  }
  .catalog {
    font-size: 1.05rem;
  }
  .error-catalog {
    font-size: 1.05rem;
  }
  .contact {
    font-size: 1.05rem;
  }
  .error-home {
    font-size: 1.05rem;
  }
  .content-inner h1 {
    font-size: 0.95rem;
  }
  .content-inner p {
    font-size: 0.8rem;
  }
  .home-catalog {
    font-size: 0.8rem;
  }
  .contact-catalog {
    font-size: 0.8rem;
  }
}
